<template>
  <!-- 关键词 文章列表 -->
  <div class="container" @mousemove.prevent="mousemove" @mouseup.prevent="imgListMouseupcoor">
    <!-- 导航 -->
    <V-ArticleNav class="headClass" :navList="navList"></V-ArticleNav>
    <div class="gWbc title" v-if="taskInfo">{{taskInfo.taskname}}</div>
    <div class="gWbc basicInfo">
      <div class="left_img_b" v-if="taskTypeTow==0">
        <i class="sx_btn el-icon-arrow-left" @click="tapImgNum(-1)" style="margin-right:20px"></i>
        <div class="left_img" @mousewheel.prevent="handleScroll" ref="imgTop">
          <img :src="api.LoginURL.concat('/resources/trading/upload'+imgList[imgNum])" ref="imgTitle" :class="{imgYd:mouseInDiv}"
          :style="{width:clientWidth/100*imgWidth+'px',top:top+'px',left:left+'px'}" v-if="imgList[imgNum]"
          @mousedown.prevent="getMousedowncoor" @load="imgLoad"> 
        </div>
        <i class="sx_btn el-icon-arrow-right" @click="tapImgNum(1)" style="margin-left:20px"></i>
      </div>
      <div class="imgControl" v-if="taskTypeTow==0">
        <div class="controlList">
          图片列表
          <el-switch v-model="imgListShow" style="margin-left:10px" active-color="#13ce66"
           inactive-color="#ff4949"></el-switch>
        </div>
        <div class="controlZomm">
          <div class="jj_btn" @click="tapJJ('+')">+</div>
          <span class="jj_bum">{{imgWidth}}%</span>
          <div class="jj_btn" @click="tapJJ('-')">-</div>
        </div>
        <div class="controlText">
          {{showFileName(imgList[imgNum])}}
        </div>
        <div class="controlText">
          图片总数：{{imgList.length}}张
        </div>
        <!-- <div class="controlText" style="cursor: pointer;border-right-width:0;" @click="imgQualityFeedback">
          图片质量反馈
        </div> -->
      </div>
      <div class="imgPreviewList" v-show="imgListShow" @mousedown.prevent="imgListMousedowncoor">
        <div class="imgYcGdt" ref="imgList">
          <div class="imgDiv" ref="imgListDiv">
            <img v-for="(url,i) of imgList" :key="i" :src="api.LoginURL.concat('/resources/trading/upload'+url)"
             class="previewList" :class="{imgBorder:i==imgNum}" @mouseup.prevent="tapSelImg(i)">
          </div>
        </div>
      </div>
      
      <div class="getInfo" v-for="(item,i) of childList" :key="i">
        <!-- <el-button type="primary" v-show="subtaskList.length>1" class="removeTask" icon="el-icon-minus"
           @click="tapRemoveSubtask(i)"></el-button> -->
        <div class="infoItem">
          <div class="item_title">
            <span>*</span>子任务名称名：
          </div>
          <div class="info_inout">
            <el-input type="text" disabled v-model="item.childTaskName" class="input" placeholder="请输入子任务名称名"></el-input>
          </div>
        </div>
        <div class="infoItem" v-if="taskTypeTow==0">
          <div class="item_title">
            <span>*</span>子任务分页：
          </div>
          <div class="info_inout" v-if="item.childTaskPaging">
            <el-input type="text" disabled :value="item.childTaskPaging.split('-')[0]" class="input_num"></el-input>
            <span style="margin-left:10px;">页</span>
            <div style="margin-left:10px;" class="input_hx"></div>
            <el-input type="text" disabled :value="item.childTaskPaging.split('-')[1]" class="input_num" style="margin-left:10px;"></el-input>
            <span style="margin-left:10px;">页</span>
            <span style="margin-left:30px;">提示：填写图片序号</span>
          </div>
        </div>
        <div class="infoItem">
          <div class="item_title">
            <span>*</span>加工酬劳设置：
          </div>
          <div class="info_inout">
            <el-input type="text" disabled v-model="item.childTaskMoney" class="input"></el-input>
            <span>知识币</span>
          </div>
        </div>
        <div class="infoItem">
          <div class="item_title">
            <span>*</span>加工工期设置：
          </div>
          <div class="info_inout">
            <el-input type="text" disabled v-model="item.childTaskDay" class="input"></el-input>
            <span>天</span>
          </div>
        </div>
      </div>

      <div class="addInfoBtn">
        <div class="addBtns">
          <el-button type="info" @click="$router.go(-1)">取消</el-button>
          <el-button type="primary" v-if="!taskInfo.jgFzrAndTaskId" style="margin-left:200px;" @click="tapConsent">同意</el-button>
          <el-button type="primary" v-else disabled style="margin-left:200px;">已确认负责人</el-button>
        </div>
      </div>
    </div>
  </div>
</template> 

<script>
import ArticleNav from "@/components/article-nav/index.vue";
import { QUERYED,UPDATES,INSERT,DELETE } from "@/services/dao.js";
export default {
  components: {
    "V-ArticleNav": ArticleNav
  }, 
  data() {
    return {
      navList:[  //导航 
        {url:'/machining',word:'消息通知'},
        {word:'任务划分通知'}
      ],
      
      id:'',   //主任务id
      fzrId:'',  //招募人id
      fzrName:'',  //负责人名称
      taskTypeTow:0,  //任务分类   0.纸质化加工、1.数字化加工、2.词条加工
      taskInfo:{},  //任务详情
      childList:[],   //划分子任务列表

      //控制图片的默认值
      imgList:[],  //图片列表
      imgNum:0,  //图片下标
      clientWidth:1,  //图片初始宽度
      imgWidth:70,  //图片缩放百分比
      mouseInDiv:false,  //是否可拖动
      top:0,  //图片位移
      left:0, //图片位移
      clientX:0, //鼠标位置
      clientY:0,   //鼠标位置
      offsetX:0,  //鼠标距离容器顶部的距离
      offsetY:0,  //鼠标距离容器左边的距离   

      // 图片列表控制
      imgListShow:false,  //是否显示图片缩略图
      isImgMouse:false,  //是否可拖动
      imgScrollLeft:0,  //拖动距离
      lastDist:0,  //上一次距离
      statejl:0,  //鼠标开始距离
      endjl:0,  //鼠标抬起后的距离
    }
  },
  created(){
    let {id,fzrId,fzrName,taskTypeTow} = this.$route.query
    this.id = id
    this.fzrId = fzrId
    this.fzrName = fzrName
    this.taskTypeTow = taskTypeTow
    this.getData(this.id)
  },
  mounted(){
    this.$nextTick(()=>{
      if(this.taskTypeTow==0){
        this.clientWidth =  this.$refs.imgTop.clientWidth
      }
    })
  },
  methods: {
    //获取任务详情
    async getData(id){
      let tableStr = ""
      //纸质化加工表
      if(this.taskTypeTow==0){
        tableStr ="jgPeper(where:{id:{_eq:"+id+"}}) {imgPath "
        +"id taskname userid username describe type zipPath resultformat isPaging isFullTextEntry checkWay processingFiles"
        +" customEntry errorRate totaldays userCreditLevel totalmoney fzr_totalmoney jgFzrAndTaskId status issueTime tasktype}"
      }else if(this.taskTypeTow == 1){
        //数字产品发布表
        tableStr ="jgDigitalPro(where:{id:{_eq:"+id+"}}) {"
        +"id taskname userid username describe type resultformat totaldays totalmoney fzr_totalmoney jgFzrAndTaskId status"
        +" title subject keyword summary classification product style textRequirement imageRequirement requirement language"
        +" framwork environment color issueTime startTimes endTime tasktype}"
      }else if(this.taskTypeTow==2){
        //词条发布表
        tableStr ="jgEntryPro(where:{id:{_eq:"+id+"}}) {"
        +"id taskname userid username describe type resultformat fujianPath totaldays totalmoney fzr_totalmoney jgFzrAndTaskId"
        +" status issueTime startTimes endTime tasktype}"
      }
      let res = await QUERYED(
        "post","",tableStr
      );
      if(res.success){
        if(this.taskTypeTow==0){
          this.taskInfo = res.data.jgPeper[0]
          let imgPath =this.taskInfo.imgPath
          this.imgList = imgPath?imgPath.split(','):[]
        }else if(this.taskTypeTow==1){
          this.taskInfo = res.data.jgDigitalPro[0]
        }else{
          this.taskInfo = res.data.jgEntryPro[0]
        }
        this.getcChildTask() 
      }
    },

    //获取子任务信息
    async getcChildTask(){
      let getDataTable ="jgChildTask(where:{fzrId:{_eq:"+this.fzrId+"},taskId:{_eq:"+this.id+"}}) {id taskType taskId"
      +" taskName fzrId fzrName childTaskId childTaskName childTaskDescribe childTaskPaging childImgpath childTaskMoney"
      +" childTaskDay}"
      let getData = await QUERYED("post","",getDataTable);
      if(getData.success){
        this.childList = getData.data.jgChildTask
      }
    },

    //点击同意
    tapConsent(){
      this.$confirm(`是否确认 ${this.fzrName} 为任务负责人？`, '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(() => {
        this.affirmFzr()
      }).catch((e) => {
        console.log(e,'err');
      });
    },

    //确认负责人
    async affirmFzr(){
      let tabs = ''
      let zshy = await QUERYED("post","","zshy(where:{type:{_eq:"+3+"}}) {content}");
      if(!zshy.success){
        msgWarn('获取数据失败，请重试')
        return
      }
      let content = zshy.data.zshy[0].content.replace(/"/g,'\\"')
      //添加招募合约
      let strRelease = "aId:"+this.taskInfo.userid+",aName:\""+this.taskInfo.username+"\",bId:"+this.fzrId+",bName:\""
        +this.fzrName+"\",content:\""+content+"\",startTime:\""+new Date()*1+"\",type:2,taskId:"+this.id+",taskName:\""
      +this.taskInfo.taskname+"\""
      let addContract = await INSERT(
        "POST","",
        'insert_jgContract(objects:{'+ strRelease +'}) {affected_rows returning {id}}'
      )
      if(!addContract.success){
        this.msgWarn('获取数据失败,请重试')
        return
      }

      //任务绑定负责人
      if(this.taskTypeTow==0){
        tabs = "update_jgPeper(_set: {jgFzrAndTaskId:"+this.fzrId+"}, where: {id: {_eq: "+this.id+"}}) {  affected_rows }"
      }else if(this.taskTypeTow==1){
        tabs = "update_jgDigitalPro(_set: {jgFzrAndTaskId:"+this.fzrId+"}, where: {id: {_eq: "+this.id+"}}) {  affected_rows }"
      }else{
        tabs = "update_jgEntryPro(_set: {jgFzrAndTaskId:"+this.fzrId+"}, where: {id: {_eq: "+this.id+"}}) {  affected_rows }"
      }
      let affirm = await UPDATES(
        "post","",tabs
      )
      if(!affirm.success){
        //绑定负责人失败删除合约数据
        await DELETE(
          "post","",
          'delete_jgContract(where: {id:{_eq:'+addContract.data.insert_jgContract.returning[0].id+'}}) {affected_rows}'
        )
        this.msgWarn('获取数据失败,请重试')
      }else{
        this.tapSendmMsg()
        this.$router.go(-1);
      }
    },

    //获取所有招募者
    async tapSendmMsg(){
      let fzrList = await QUERYED("post","","jgFzrAndTask(where:{jgPeperid:{_eq:"+this.id+"}}) {id fzrId fzrName jgPeperid taskname}");
      if(fzrList.success){
        let arrList = fzrList.data.jgFzrAndTask
        console.log(arrList,123);
        this.sendMsgInfo(arrList,0)
      }
    },

    //递归发送消息
    async sendMsgInfo(arrList,num){
      
      if(arrList.length<=num){
        return
      }
      let {fzrId,fzrName,jgPeperid,taskname} = arrList[num]
        console.log(fzrId,fzrName,jgPeperid,taskname);
      let msg = await INSERT(
        "POST","",
        "insert_jgMessage(objects:{messageType:8,taskTime:"+new Date()*1+",taskName:\""+taskname
          +"\",taskType:0,taskId:"+jgPeperid+",taskFatherId:"+jgPeperid+",userId:"+fzrId+",userType:2"
          +",fzrName:\""+fzrName+"\",taskTypeTow:"+this.taskTypeTow+",complaintStatus:"+
          (this.fzrId==fzrId?1:0)
          +"}) {affected_rows returning {id}}"
      )
      num++
      this.sendMsgInfo(arrList,num)
    },

    //警告弹窗
    msgWarn(message,type){
      this.$message({
        message,type: type||'warning'
      });
    },
    //判断值是否为空
    pdIsEmpty(e){
      return e.replace(/ /g,'')
    },

    //获取图片或文件名字
    showFileName(text){
      if(text){
        let list = text?text.split('/'):[]
        return list[list.length-1]
      }
    },

    //图片上一页、下一页
    tapImgNum(num){
      let min = 0
      let max = this.imgList.length -1
      let imgNum = this.imgNum
      imgNum += num*1
      if(imgNum<min){
        this.msgWarn('前面已经没有了')
        return        
      }
      if(imgNum>max){
        this.msgWarn('后面已经没有了')
        return        
      }
      this.imgNum = imgNum
      this.left = 0 //图片位移
      this.top = 0   //图片位移
      this.imgWidth = 70
    },
    
    //图片加载时
    imgLoad(e){
      let h = this.$refs.imgTop.clientHeight
      let w = this.$refs.imgTop.clientWidth
      let h1 = e.target.clientHeight
      let w1 = e.target.clientWidth
      if(h1<h){
        this.top = parseInt((h-h1)/2)
      }
      if(w1<w){
        this.left = parseInt((w-w1)/2)
      }
    },

    //判断滚动方向对图片进行放大缩小
    handleScroll(e){
      let direction = 'down'
      if(e.wheelDelta){
        direction = e.wheelDelta>0? 'up' : 'down'
      }
      if(e.detail){
        direction = e.detail>0? 'up' : 'down'
      }
      if(direction == 'down'){
        this.imgWidth -=1
        if(this.imgWidth<30)this.imgWidth = 30
      }else{
        this.imgWidth +=1
        if(this.imgWidth>300)this.imgWidth = 300
      }  
    },

    // 鼠标按下
    getMousedowncoor(e) {
      this.mouseInDiv = true
      this.clientX = e.clientX
      this.clientY = e.clientY
    },

    // 鼠标移动
    mousemove(e){ 
      let {clientX,clientY} = e
      //大图鼠标移动
      if(this.mouseInDiv){
        this.left += clientX - this.clientX
        this.clientX = clientX
        this.top += clientY - this.clientY
        this.clientY = clientY
        let {clientWidth,clientHeight} = this.$refs.imgTop
        let w = this.$refs.imgTitle.clientWidth
        let h = this.$refs.imgTitle.clientHeight
        if(w<=clientWidth){
          if(this.left<0)this.left = 0
          if(this.left>(clientWidth-w))this.left = clientWidth-w
        }else{
          if(this.left>0)this.left = 0
          if(this.left<(clientWidth-w))this.left = clientWidth-w
        }
        if(h<=clientHeight){   
          if(this.top<0)this.top = 0
          if(this.top>(clientHeight-h))this.top = clientHeight-h
        }else{
          if(this.top>0)this.top = 0
          if(this.top<(clientHeight-h))this.top = clientHeight-h
        }
      }

      //列表鼠标移动
      if(this.isImgMouse){
        let imgList = this.$refs.imgList
        let fw = imgList.clientWidth
        let w = this.$refs.imgListDiv.clientWidth - fw
        if(this.lastDist<=0) {
          this.lastDist = clientX
        }else{
          this.imgScrollLeft -= (clientX - this.lastDist)
          if(this.imgScrollLeft<=0)this.imgScrollLeft = 0
          if(this.imgScrollLeft>w)this.imgScrollLeft = w
          imgList.scrollLeft = this.imgScrollLeft
          this.lastDist = clientX
          this.endjl = clientX
        }
      }
    },

    //点击按钮放大缩小图片
    tapJJ(val){
      if(val=='+'){
        this.imgWidth += 10
      }else{
        this.imgWidth -= 10
      }
      if(this.imgWidth<30)this.imgWidth = 30
      if(this.imgWidth>300)this.imgWidth = 300
    },

    //图片列表鼠标按下
    imgListMousedowncoor(e){
      this.isImgMouse = true
      this.lastDist = 0
      this.statejl = e.clientX
    },

    //鼠标抬起
    imgListMouseupcoor(e){
      this.isImgMouse = false
      this.mouseInDiv = false 
    },

    //选择图片时
    tapSelImg(i){
      let num = Math.abs(this.statejl - this.endjl)
      if(this.endjl==0||num<5){
        this.imgNum = i
        // this.imgListShow = false
      }
      this.statejl = 0
      this.endjl = 0
      this.left = 0 //图片位移
      this.top = 0   //图片位移
      this.imgWidth = 70
    }
  }
};
</script>

<style scoped>
.container {
  background-color: #EEEEEE;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headClass {
  width: 1090px;
  height: 66px;
  line-height: 66px;
  text-align: left;
}
.gWbc{
  width: 1090px;
  background-color: #fff;
}
.title{
  height: 72px;
  line-height: 72px;
  text-align: center;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  border-bottom: 3px solid #eee;
}
.basicInfo{
  padding-bottom: 40px;
  box-sizing: border-box;
}
.ente_one{
  width: 100%;
  height: 66px;
  display: flex;
  border:2px solid #eee;
  border-top: 0;
  box-sizing: border-box;
}
.ente_one>div{
  width: 50%;
  text-align: center;
  line-height: 66px;
  font-size: 14px;
  color: #000;
}
.ente_one>div:last-child{
  font-size: 18px;
  border-left: 2px solid #eee;
}
.two_right{
  width: 50%;
  border-left: 2px solid #eee;
}
.left_img_b{
  width: 100%;
  height: 500px;
  padding:10px;
  border-bottom: 2px solid #eee;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.left_img{
  width: 100%;
  flex: 1;
  background-color: #eee;
  height: 100%;
  overflow: hidden;
}
.left_img>img{
  position: relative;
}
img.imgYd{
  cursor: move;
}
.imgControl{
  width: 100%;
  height: 66px;
  border-bottom:2px solid #eee;
  display: flex;
  align-items: center;
}
.jj_btn{
  width: 22px;
  height: 22px;
  margin-left: 28px;
  border:2px solid #ddd;
  border-radius: 50%;
  text-align: center;
  line-height: 22px;
  font-size: 18px;
  cursor: pointer;
}
.jj_bum{
  width: 50px;
  text-align: center;
  margin-left: 20px;
  font-size: 16px;
  cursor: pointer;
}
.sx_btn{
  /* background-color: #EEEEEE; */
  font-size: 60px;
  font-weight: bold;
  color: #909399;
  cursor: pointer;
}
.sx_btn:hover{
  opacity: 0.8;
}
.controlList{
  width: 179px;
  height: 100%;
  /* border-left: 2px solid #eee; */
  border-right: 2px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}
.controlZomm{
  width: 208px;
  height: 100%;
  border-right: 2px solid #eee;
  display: flex;
  align-items: center;
}
.controlText{
  flex: 1;
  height: 100%;
  border-right: 2px solid #eee;
  display: flex;
  align-items: center;
  justify-content: center;
}
.imgPreviewList{
  width: 100%;
  height: 90px;
  border-bottom:2px solid #eee;
  overflow: hidden;
}
.imgYcGdt{
  width:100%;
  height: 110px;
  overflow-x: auto;
}
.imgDiv{
  min-width: 100%;
  height: 90px;
  white-space:nowrap;
  display:inline-block;
}
.previewList{
  height: 90px;
  display:inline-block;
}
.imgBorder{
  border:2px solid #000;
  box-sizing: border-box;
}
.getInfo{
  width: 100%;
  padding:50px 0;
  border-bottom:2px solid #eee;
  position: relative;
}
.removeTask{
  width: 26px;
  height: 26px;
  padding:0;
  position: absolute;
  top: 10px;
  right: 20px;
}
.infoItem{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.infoItem+.infoItem{
  margin-top:20px;
}
.item_title{
  width: 150px;
  text-align: right;
  font-size: 14px;
  color:#000;
}
.item_title>span{
  color:red;
}
.info_inout{
  width: 350px;
  height: 36px;
  margin-left: 20px;
  display: flex;
  align-items: center;
}
.info_inout>span{
  margin-left: 10px;
}
.input{
  flex: 1;
  height: 36px;
  line-height: 36px;
}
.input_num{
  width: 60px;
  height: 36px;
}
.input_hx{
  width: 20px;
  height: 2px;
  background-color: #ddd;
}
.addInfoBtn{
  width: 100%;
}
.addBtn{
  padding:0 20px;
  padding-top: 10px;
  display: flex;
  justify-content:space-between;
  align-items: center;
}
.addBtn>span{
  font-size: 14px;
  color:#000;
}
.addBtn>.el-button{
  width: 26px;
  height: 26px;
  padding:0;
  margin-left:10px;
}
.addBtns{
  padding-top: 30px;
  display: flex;
  justify-content: center;
}
.keepoOut{
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba(0,0,0,0.2);
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.keepDiv{
  width: 854px;
  background-color: #FFFFFF;
}
.keepTitle{
  width: 100%;
  height: 66px;
  background-color:#E5E5E5 ;
  text-align: center;
  line-height: 66px;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  color: #000;
}
.keepBack{
  width: 21px;
  height: 21px;
  border:2px solid #999;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right:10px;
  cursor: pointer;
}
.keepBack:hover{
  opacity: 0.8;
}
.keepBack>i{
  font-size: 30px;
  color:#999;
}
.keepinput{
  width: 100%;
  padding:70px 0;
}
/* .input >>> .el-textarea__inner{
  height: 100px !important;
} */
.areaStyle{
  align-items:flex-start;
  height: 200px;
}
.areaStyle1>.item_title{ 
  width: 80px;
}
.areaStyle>.item_title{ 
  width: 80px;
}
.areaStyle>.info_inout{
  height: 200px;
  align-items: flex-start;
}
.areaStyle>.input{
  height: 200px;
}
.areaStyle>>>.el-textarea__inner{
  height: 200px;
}
</style>
